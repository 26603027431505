import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import {Component} from "react";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import './styles/output.css'
import './styles/App.css'

class App extends Component {
  render() {
    return (
        <Router>
          <div className="container mx-auto">
            <Routes>
              <Route exact path='/' element={< Home />}> </Route>
              <Route exact path='/privacy' element={< PrivacyPolicy />}> </Route>
            </Routes>
            <ul className="flex justify-items-center justify-center text-center">
              <li className="mx-8">
                <Link to="/">Home</Link>
              </li>
              <li className="mx-8">
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li className="mx-8">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </Router>
    )
  }
}

export default App;