import React from 'react';
import banner from './images/feature-1.png'
import gplay from './images/google-play-badge.png'
import appstore from './images/app_store_black.png'
import './styles/output.css'

function Home() {
    return (
        <div className="flex justify-center justify-items-center">
            <header className="Home-header">
                <div className="flex flex-col justify-center">
                    <div>
                        <img src={banner} className="object-center" alt="logo" />
                    </div>
                    <div>
                        <p className="text-center mt-8">
                            Download The App Now!
                        </p>
                        <div className="flex flex-rows items-center justify-center justify-items-center">
                            <div className="flex justify-center justify-items-center h-24 shrink-0">
                                <a target="_blank" rel="noreferrer" about="Match2048 on Google Play Store" href="https://play.google.com/store/apps/details?id=com.braydeejohnson.match2048"><
                                    img src={gplay} className="google-play-logo" alt="google-play-logo" className="h-24"/>
                                </a>
                            </div>
                            {/*<div className="flex justify-center justify-items-center h-16 shrink-0">*/}
                            {/*    <a target="_blank" rel="noreferrer" about="Match2048 on Apple App Store" href="">*/}
                            {/*        <img src={appstore} className="app-store-logo" alt="app-store-logo" className="h-16"/>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

            </header>
        </div>
    );
}

export default Home;
